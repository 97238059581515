import { graphql, useStaticQuery } from 'gatsby';
import Solutions from 'svgs/expertises/solutions.svg';
import StreamingSolutions from 'svgs/expertises/streaming-solutions.svg';
import { formatFaqData } from 'utilities/contentful';

import { HUB_URLS } from '../constants';
import { resolveBlogPageContent } from '../utilities';

const content = resolveBlogPageContent({
    page: 'hr',
    paragraphsCounters: { firstRow: 1, secondRow: 1 },
    quoteAuthor: {
        name: 'aneta-wodarz',
        position: 'hr-business-partner',
    },
    showNftBanner: true,
});

export const useBlogHr = (blogPosts, faqResult) => {
    const subInfoLinksData = [
        {
            key: 'employeeLink',
            url: 'blog/hr/working-at-home-vs-office',
            component: 'gatsby-link',
        },
        {
            key: 'hrLink',
            url: 'blog/navigating-hr-realities-unveiling-the-hr-work-at-mobile-reality',
            component: 'gatsby-link',
        },
    ];

    const firstRowLinksData = [
        {
            key: 'engagedLink',
            url: 'blog/hr/how-to-increase-employee-engagement',
            component: 'gatsby-link',
        },
    ];

    const images = useStaticQuery(
        graphql`
            query {
                hrAvatar: file(relativePath: { eq: "team/members/aneta.png" }) {
                    childImageSharp {
                        gatsbyImageData(
                            quality: 100
                            placeholder: NONE
                            width: 74
                            formats: [WEBP]
                        )
                    }
                }
            }
        `,
    );

    const svgs = {
        firstRowSvg: StreamingSolutions,
        secondRowSvg: Solutions,
    };

    const faqData = formatFaqData(faqResult);

    content.quoteAuthor.image = images.hrAvatar.childImageSharp.gatsbyImageData;

    return {
        ...images,
        ...content,
        ...svgs,
        posts: blogPosts,
        hideIndustryLeaders: true,
        hub: HUB_URLS.HR,
        faqData,
        subInfoLinksData,
        firstRowLinksData,
    };
};
