import { graphql, useStaticQuery } from 'gatsby';
import TechnicalAnalysis from 'svgs/images/technical-analysis.svg';
import Javascript from 'svgs/technologies/node-js/javascript.svg';
import JsDevelopment from 'svgs/technologies/node-js/node-js-development.svg';
import { formatFaqData } from 'utilities/contentful';

import { HUB_URLS } from '../constants';
import { getBlogPagePosts, resolveBlogPageContent } from '../utilities';

const content = resolveBlogPageContent({
    page: 'javascript',
    paragraphsCounters: { firstRow: 1, secondRow: 1, thirdRow: 1 },
    quoteAuthor: {
        name: 'marcin-sadowski',
        position: 'cto-javascript-expert',
    },
});

export const useBlogJavascript = (blogPosts, faqResult) => {
    const { industryLeadersPosts, posts } = getBlogPagePosts(
        blogPosts,
        'industry-leaders',
    );

    const firstRowLinksData = [
        {
            key: 'webDevelopmentLink',
            url: 'services/web-development',
            component: 'gatsby-link',
        },
        {
            key: 'reactLink',
            url: 'technologies/hire-react-js-developers',
            component: 'gatsby-link',
        },
        {
            key: 'vueLink',
            url: 'technologies/hire-vue-js-developers',
            component: 'gatsby-link',
        },
        {
            key: 'nextLink',
            url: 'blog/nextjs-server-sider-rendering-guidline',
            component: 'gatsby-link',
        },
    ];

    const thirdRowLinksData = [
        {
            key: 'mobileDevelopmentLink',
            url: 'services/mobile-development',
            component: 'gatsby-link',
        },
        {
            key: 'reactNativeLink',
            url: 'technologies/hire-react-native-developers',
            component: 'gatsby-link',
        },
        {
            key: 'mobileComparisonLink',
            url: 'blog/xamarin-vs-flutter-vs-react-native',
            component: 'gatsby-link',
        },
    ];

    const images = useStaticQuery(
        graphql`
            query {
                ctoAvatar: file(relativePath: { eq: "team/cto-avatar.png" }) {
                    childImageSharp {
                        gatsbyImageData(
                            quality: 100
                            placeholder: NONE
                            width: 74
                            formats: [WEBP]
                        )
                    }
                }
            }
        `,
    );

    const svgs = {
        firstRowSvg: JsDevelopment,
        secondRowSvg: Javascript,
        thirdRowSvg: TechnicalAnalysis,
    };

    const faqData = formatFaqData(faqResult);

    content.quoteAuthor.image =
        images.ctoAvatar.childImageSharp.gatsbyImageData;

    return {
        ...images,
        ...content,
        ...svgs,
        faqData,
        industryLeadersPosts,
        posts,
        hub: HUB_URLS.JAVASCRIPT,
        firstRowLinksData,
        thirdRowLinksData,
    };
};
